<template>
  <div class="home">
    <WelcomeBox />
  </div>
</template>

<script>
// @ is an alias to /src
import WelcomeBox from '@/components/WelcomeBox.vue'

export default {
  name: 'Welcome',
  components: {
    WelcomeBox
  }
}
</script>

<style scoped>
  .home {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background: url('../assets/home-bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
</style>
