<template>
    <div class="marker-details row">
        <div class="pane col s12 m6 l4 offset-m6 offset-l8 grey darken-3 blue-grey darken-4 white-text">
            <div class="row content">
                <div class="col s12">
                    <div class="lead">Details <i class="fas fa-times close right" v-on:click="close()"></i></div>
                    <ul>
                        <li><b>Coords:</b> {{mgrs.forward([details.lon, details.lat])}}<br />{{details.lat.toFixed(6)}} {{details.lon.toFixed(6)}}</li>

                    </ul>
                    <div class="row commands">
                        <div class="col s12 lead">Commands</div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="showRenameInput = !showRenameInput">Rename</a>
                        </div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="showArriveDepartInput = !showArriveDepartInput">Arive/Depart</a>
                        </div>
                        <div class="col s12 m6 l4" v-if="history.length > 0">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="showHistory = !showHistory">History</a>
                        </div>
                    </div>
                    <div class="row commands input" v-if="showRenameInput">
                        <div class="input-field col s12">
                            <textarea id="textarea1" class="materialize-textarea white-text" v-model="name"></textarea>
                            <label for="textarea1">Enter new name</label>
                        </div>
                        <div class="col s12 m4 offset-m8">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="rename_point()">Send</a>
                        </div>
                    </div>
                    <div class="row commands input" v-if="showArriveDepartInput">
                        <div class="input-field col s6">
                            <select v-model="selectedCallsign" class="browser-default">
                                <option value="" disabled selected>Choose callsign</option>
                                <option v-for="(callsign,index) in callsigns" :key="index" :value="callsign.nickname">{{callsign.nickname}}</option>
                            </select>
                        </div>
                        <div class="input-field col s12 m3">
                            <label>
                                <input name="direction" type="radio" value="arrive" v-model="direction" />
                                <span>Arrive</span>
                            </label>
                        </div>
                        <div class="input-field col s12 m3">
                            <label>
                                <input name="direction" type="radio" value="depart" v-model="direction" />
                                <span>Depart</span>
                            </label>
                        </div>
                        <div class="input-field col s4 offset-s8">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="log_event()">Send</a>
                        </div>
                    </div>
                    <div class="row commands input" v-if="showHistory">
                        <div class="col s12" style="font-size: 13pt; margin-bottom: 25px;">
                            <p  v-for="item in pointHistory" :key="item.uuid" style="clear: both; padding: 5px 0px 5px 0px">
                                <b class="left">{{ item.callsign }}</b>
                                <span class="left" style="margin-left: 15px;">{{ item.direction }}</span>
                                <span class="right">{{ item.time }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col s12 uuid"><span class="left">{{details.nickname}} <i class="fas fa-trash yellow-text text-darken-1" v-on:click="remove_point(details.uuid)"></i></span>{{details.uuid}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import M from 'materialize-css'
import * as mgrs from "mgrs"
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'PointDetails',
    data: function () {
        return {
            mgrs: mgrs,
            name: '',
            showRenameInput: false,
            showArriveDepartInput: false,
            showHistory: false,
            pointHistory: [],
            callsigns: [],
            selectedCallsign: '',
            direction: ''
        }
    },
    props: {
        details: Object,
        markers: Array,
        history: Array
    },
    methods: {
        close: function () {
            this.$emit('closePointDetails')
        },
        remove_point: function (uuid) {
            this.$socket.emit('remove_point', uuid)
            this.close()
        },
        rename_point: function () {
            let data = this.details

            data.name = this.name
            this.$socket.emit('point_rename', data)
            this.close()
        },
        log_event: function () {
            let event = {}
            
            event.uuid = uuidv4()
            event.pointUUID = this.details.uuid
            event.name = this.details.nickname
            event.callsign = this.selectedCallsign
            event.direction = this.direction
            event.time = new Date().toLocaleString()

            if (event.callsign != '' && event.direction != '') {
                this.$socket.emit('log_event', event)
                console.log(event)
                this.close()
                M.toast({html: `Yay!:\xa0\xa0<b style="font-weight: 700;">Event logged</b>`, classes: 'rounded alert'})
            } else {
                if (event.callsign === '' && event.direction === '') {
                    M.toast({html: `Oops!:\xa0\xa0<b style="font-weight: 700;">Must select callsign and type</b>`, classes: 'rounded alert'})
                }
                if (event.callsign === '' && event.direction != '') {
                    M.toast({html: `Oops!:\xa0\xa0<b style="font-weight: 700;">Must select callsign</b>`, classes: 'rounded alert'})
                }
                if (event.callsign != '' && event.direction === '') {
                    M.toast({html: `Oops!:\xa0\xa0<b style="font-weight: 700;">Must select direction</b>`, classes: 'rounded alert'})
                }
            }
        }
    },
    computed: {
        options () {
            return Object.keys(this.callsigns).map(k => {
                let o = this.callsigns[k]
                return o.nickname
            })
        }
    },
    mounted: function () {
        window.$(document).ready(function(){
            M.AutoInit()
        });

        var self = this
        self.markers.forEach(function (marker) {
            if (marker.type === 'user') {
                self.callsigns.push(marker)
            }
        })

        self.history.forEach(function (h) {
            if (h.uuid === self.details.uuid) {
                console.log(h)
                h.events.forEach(function(e) {
                    self.pointHistory.push(e)
                })
            }
        })
    }
}
</script>

<style scoped>
    .tooltipped {
        z-index: +10;
    }

    .marker-details {
        z-index: +2;
        position: absolute;
        top: 82px;
        width: 100%;
        margin: 0px;
    }

    .pane {
        margin: 0px;
        border-top: 1px solid #fdd835;
    }

    .pane .content {
        margin: 15px 0px 15px 0px;
    }

    .content .lead {
        width: 100%;
        margin-bottom: 10px;
        font-size: 20pt;
        font-weight: 600;
    }

    .lead .close {
        cursor: pointer;
    }

    .content ul {
        font-size: 14pt;
        margin: 0px;
    }

    li.movement {
        margin-left: 10px;
        font-size: 12pt;
        font-weight: 400;
    }

    .uuid {
        margin-top: -15px;
        text-align: right;
        font-size: 10pt;
        font-weight: 700;
    }

    .commands .lead {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .commands a {
        width: 100%;
        margin-bottom: 15px;
    }

    .input-field textarea:focus + label {
        color: #fdd835 !important;
    }

    .row .input-field textarea:focus {
        border-bottom: 1px solid #fdd835 !important;
        box-shadow: 0 1px 0 0 #fdd835 !important
    }

    .uuid i {
        padding: 5px;
        cursor: pointer;
    }

    @media only screen and (max-width: 1200px) {
        .marker-details {
            z-index: +2;
            position: absolute;
            top: 80px;
            width: 100%;
            margin: 0px;
        }
    }
</style>