<template>
    <div class="wrapper">
        <OpnetNav
            :map="map"
            v-if="map != null" />
        <OpnetBottomBar
            :version="version"
            :map="map"
            v-if="map != null" />
        <MarkerDetails
            :details="markerDetails"
            v-if="markerDetails != null"
            v-on:closeMarkerDetails="markerDetails = null" />
        <PointDetails
            :details="markerDetails"
            :markers="markers"
            :history="pointHistory"
            v-if="markerDetails != null && markerDetails.type === 'point'"
            v-on:closePointDetails="markerDetails = null" />
        <InstructionAlert
            :instruction="instruction"
            v-if="instruction != null"
            v-on:closeInstruction="instruction = null" />
        <Whitecell
            :whitecell="whitecell"
            v-if="whitecell.length > 0" />
        <div class="map" id="map"></div>
        <div class="map-controls">
            <div class="row" style="margin-bottom: 0px;">
                <i class="addPoint fas fa-plus yellow-text text-darken-1" v-on:click="addPoint()"></i>
                <i class="addKML fas fa-upload yellow-text text-darken-1" v-on:click="showKML = !showKML"></i>
                <i class="viewLayers fas fa-layer-group yellow-text text-darken-1" v-on:click="countLayers"></i>
                <i class="changeBaseLayer fas fa-map yellow-text text-darken-1" v-on:click="changeBaseLayer()"></i>
            </div>
            <div class="row white-text" style="margin-top: 20px; margin-bottom: 0px;" v-if="showKML">
                <div class="col s12">
                    <a href="#" class="btn yellow darken-1 black-text" style="width: 100%;" v-on:click="promptKML()">Import</a>
                </div>
                <input type="file" @change="importData" id="importState" ref="importState" style="display: none;" />
            </div>
        </div>
        <div class="map-layers" v-if="showLayers">
            <div class="row" style="margin-bottom: 0px;">
                <div class="col s12" v-for="layer in layers" :key="layer.name">
                    {{ layer.nickname }} <i class="fas fa-ban red-text right" v-on:click="remove_layer(layer.uuid)"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OpnetNav from '@/components/Opnet/OpnetNav.vue'
import OpnetBottomBar from '@/components/Opnet/OpnetBottomBar.vue'
import MarkerDetails from '@/components/Opnet/MarkerDetails.vue'
import PointDetails from '@/components/Opnet/PointDetails.vue'
import InstructionAlert from '@/components/Opnet/InstructionAlert.vue'
import Whitecell from '@/components/Opnet/Whitecell.vue'
import { v4 as uuidv4 } from 'uuid'
import alertSound from '@/assets/alert.wav'
import * as toGeoJSON from '@/assets/toGeoJSON.js'
import M from 'materialize-css'

export default {
    name: 'Opnet',
    components: {
    OpnetNav,
    OpnetBottomBar,
    MarkerDetails,
    PointDetails,
    InstructionAlert,
    Whitecell
},
    data: function () {
        return {
            nickname: localStorage.getItem("nickname"),
            isWhitecell: null,
            map: null,
            layer: null,
            version: '0.05',
            geoWatchId: 0,
            myID: null,
            mapStyles: {
                currentIndex: 0,
                styles: [
                    'mapbox://styles/mapbox/dark-v9',
                    'mapbox://styles/mapbox/light-v9',
                    'mapbox://styles/mapbox/satellite-streets-v10'
                ]
            },
            markers: [],
            layers: [],
            pointHistory: [],
            tracks: [],
            whitecell: [],
            markerDetails: null,
            lineDetails: null,
            geoLocation: {
                latitude: null,
                longitude: null,
                altitude: null,
                speed: null,
                heading: null
            },
            instruction: null,
            showKML: false,
            showLayers: false,
            importState: '',
            routeColors: [
                { color: '#ff1744', used: false },
                { color: '#18ffff', used: false },
                { color: '#76ff03', used: false },
                { color: '#2962ff', used: false },
                { color: '#d500f9', used: false },
            ],
            currentlyPlotting: false
        }
    },
    methods: {
        initMap: function () {
            var self = this

            window.mapboxgl.accessToken = 'pk.eyJ1IjoibWF0dGhld2phbWVzIiwiYSI6Ik5hWHoxc2sifQ.VDda6nb8doJs-wC82yslSg';

            var baseLayer = new window.maptalks.MapboxglLayer('tile',{
                glOptions : {
                    'style' : self.mapStyles.styles[self.mapStyles.currentIndex]
                }
            })

            this.map = new window.maptalks.Map('map', {
                center: [-86.80702, 30.51454],
                zoom: 11.5,
                baseLayer: baseLayer
            }).on('click', function (param) {
                if (self.currentlyPlotting) {
                    self.currentlyPlotting = false
                    self.map.setCursor('default')
                    self.broadcastPoint(param.coordinate.toFixed(5).x, param.coordinate.toFixed(5).y);
                }
            })

            this.layer = new window.maptalks.VectorLayer('v').addTo(this.map)

            var marker1 = new window.maptalks.Marker(
                [self.geoLocation.longitude, self.geoLocation.latitude],
                {
                'symbol' : {
                    'markerType': 'ellipse',
                    'markerFill': "#2196f3",
                    'markerFillOpacity': 1,
                    'markerLineColor': "#42a5f5",
                    'markerLineWidth': 3,
                    'markerLineOpacity': 1,
                    'markerLineDasharray':[],
                    'markerWidth': 25,
                    'markerHeight': 25,
                    'markerDx': 0,
                    'markerDy': 0,
                    'markerOpacity' : 0.5,
                    'textName': self.nickname,
                    'textWeight': '900',
                    'textFont': 'small-caps bold 17px/1 sans-serif',
                    'textFill': '#fff',
                    'textHaloFill': '#000',
                    'textHaloRadius': '2',
                    'textDy': '-20',
                    'textDx': '50'
                }
                }
            ).on('click', function () {
                self.showMarkerDetails(self.myID)
            }).addTo(self.layer)

            self.markers.push({uuid: self.myID, type: 'user', nickname: self.nickname, geolocation: null, marker: marker1})
        },
        enableGeolocationServices: function () {
            const options = {
                enableHighAccuracy: true,
                maximumAge: 10000
            }
            if (navigator.geolocation) {
                console.log('Geolocation services started!')
                self.geoWatchId = navigator.geolocation.watchPosition(this.showLocation, this.locationError, options)
            } else { 
                alert("Geolocation is not supported by this browser.")
            }
        },
        showLocation: function (position) {
            this.geoLocation.longitude = position.coords.longitude
            this.geoLocation.latitude = position.coords.latitude
            this.geoLocation.altitude = position.coords.altitude
            this.geoLocation.speed = position.coords.speed
            this.geoLocation.heading = position.coords.heading

            localStorage.setItem("cachedLon", position.coords.longitude);
            localStorage.setItem("cachedLat", position.coords.latitude);
            
            this.updatePosition()
        },
        locationError: function (error) {
            //alert(`ERROR(${error.code}): ${error.message}`);
            switch(error.code) {
                case error.PERMISSION_DENIED:
                    alert("User denied the request for Geolocation or access is blocked by your sysadmin.")
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.")
                    break;
                case error.TIMEOUT:
                    console.log("The request to get user location timed out.")
                    break;
                case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.")
                    break;
            }
        },
        updatePosition: function () {
            if (this.geoLocation.latitude != null) {
                this.$socket.emit('location_update', {uuid: this.myID, name: this.nickname, geolocation: this.geoLocation}) 
            }           
        },
        showMarkerDetails: function (uuid) {
            var self = this
            this.markers.forEach(function(marker) {
                if (marker.uuid === uuid) {
                    self.markerDetails = marker
                }
            })
        },
        changeBaseLayer: function () {
            var self = this

            if (self.mapStyles.currentIndex == 2) {
                self.mapStyles.currentIndex = 0
            } else {
                self.mapStyles.currentIndex++
            }

            var layer = new window.maptalks.MapboxglLayer('tile',{
                glOptions : {
                    'style' : self.mapStyles.styles[self.mapStyles.currentIndex]
                }
            })

            this.map.setBaseLayer(layer)
        },
        addPoint: function () {
            this.map.setCursor('crosshair')
            this.currentlyPlotting = true
        },
        broadcastPoint: function (lon, lat) {
            let data = {}

            data.uuid = uuidv4()
            data.lon = lon
            data.lat = lat
            data.name = 'Click to name'
            data.info = null

            this.$socket.emit('point_update', data)
        },
        renamePoint: function (uuid) {
            let data = {}

            data.uuid = uuid
            data.name = prompt('Enter new point name:')
            this.$socket.emit('point_rename', data)
        },
        addKML: function () {
            this.showUpload = true;
        },
        importData: function () {
            var self = this
            let file = this.$refs.importState.files[0]
            let reader = new FileReader()
            reader.readAsText(file, "UTF-8")
            reader.onload =  evt => {
                let data = {}
                
                data.uuid = uuidv4()
                data.name = file.name
                data.geojson = toGeoJSON.kml(new DOMParser().parseFromString(evt.target.result, 'text/xml'), { styles: true })

                this.$socket.emit('layer_update', data)

                self.showKML = false
            }
            reader.onerror = evt => {
                console.error(evt)
            }
        },
        promptKML: function () {
            document.getElementById('importState').click()
        },
        countLayers: function () {
            var self = this

            if (self.showLayers) {
                self.showLayers = false
            } else {
                if (self.layers.length > 0) {
                    self.showLayers = true
                } else {
                    M.toast({html: `Oops!:\xa0\xa0<b style="font-weight: 700;">No overlays loaded</b>`, classes: 'rounded alert'})
                }
            }
        },
        remove_layer: function (uuid) {
            this.$socket.emit('remove_layer', uuid)
            this.showLayers = false
            M.toast({html: `Yay!:\xa0\xa0<b style="font-weight: 700;">Layer removed</b>`, classes: 'rounded alert'})
        }
    },
    sockets: {
        location_update: function (data) {
            var self = this
            let markerOutlineColor = ""
            let markerFillColor = ""
            var matchFound = false

            //console.log(JSON.stringify(data))

            if (data.uuid === this.myID) {
                markerOutlineColor = "#42a5f5"
                markerFillColor = "#2196f3"
            } else {
                markerOutlineColor = "#ff1744"
                markerFillColor = "#c62828"
            }

            self.markers.forEach(function (marker) {
                if (marker.uuid === data.uuid && data.geolocation != null) {
                    marker.marker.setCoordinates([data.geolocation.longitude, data.geolocation.latitude])
                    marker.geolocation = data.geolocation
                    matchFound = true
                }
            })

            if (matchFound != true && data.geolocation != null) {
                var marker1 = new window.maptalks.Marker(
                    [data.geolocation.longitude, data.geolocation.latitude],
                    {
                    'symbol' : {
                        'markerType': 'ellipse',
                        'markerFill': markerFillColor,
                        'markerFillOpacity': 1,
                        'markerLineColor': markerOutlineColor,
                        'markerLineWidth': 3,
                        'markerLineOpacity': 1,
                        'markerLineDasharray':[],
                        'markerWidth': 25,
                        'markerHeight': 25,
                        'markerDx': 0,
                        'markerDy': 0,
                        'markerOpacity' : 0.5,
                        'textName': data.nickname,
                        'textWeight': '900',
                        'textFont': 'small-caps bold 17px/1 sans-serif',
                        'textFill': '#fff',
                        'textHaloFill': '#000',
                        'textHaloRadius': '2',
                        'textDy': '-20',
                        'textDx': '50'
                    }
                    }
                ).on('click', function () {
                    self.showMarkerDetails(data.uuid)
                }).addTo(self.layer)

                self.markers.push({uuid: data.uuid, type: 'user', nickname: data.nickname, geolocation: data.geolocation, marker: marker1})
            }
        },
        remove_user: function (data) {
            console.log(`Remove user: ${data}`)
            
            var self = this
            this.markers.forEach(function(marker, index) {
                if (marker.uuid === data) {
                    marker.marker.remove()
                    self.markers.splice(index, 1)
                }
            })

            console.log(JSON.stringify(this.markers))
        },
        whitecell_update: function (data) {
            this.whitecell.push(data)
        },
        remove_whitecell: function (data) {
            console.log(`Remove whitecell: ${data}`)
            
            var self = this
            this.whitecell.forEach(function(whitecell, index) {
                if (whitecell.uuid === data) {
                    self.whitecell.splice(index, 1)
                }
            })

            console.log(JSON.stringify(this.markers))
        },
        point_update: function (data) {
            var self = this
            var matchFound = false

            self.markers.forEach(function (marker) {
                if (marker.uuid === data.uuid) {
                    console.log(`Updating point: ${data.uuid}`)
                    marker.marker.setCoordinates([data.lon, data.lat])
                    marker.marker.updateSymbol({
                        textName: data.name
                    })
                    marker.nickname = data.name
                    matchFound = true
                }
            })

            if (matchFound != true) {
                var marker1 = new window.maptalks.Marker(
                    [data.lon, data.lat],
                    {
                    'symbol' : {
                        'markerType': 'triangle',
                        'markerFill': "red",
                        'markerLineColor': '#000',
                        'markerLineWidth': '2',
                        'markerLineDasharray':[],
                        'markerWidth': 15,
                        'markerHeight': 15,
                        'markerDx': 0,
                        'markerDy': 0,
                        'markerOpacity' : 0.5,
                        'textName': data.name,
                        'textWeight': '900',
                        'textFont': 'small-caps bold 14px/1 sans-serif',
                        'textFill': '#fff',
                        'textHaloFill': '#000',
                        'textHaloRadius': '2',
                        'textDy': '20',
                        'textDx': '0'
                    }
                    }
                ).on('click', function () {
                    self.showMarkerDetails(data.uuid)
                }).addTo(self.layer)

                self.markers.push({uuid: data.uuid, type: 'point', nickname: data.name, lon: data.lon, lat: data.lat, marker: marker1})
            }
        },
        remove_point: function (data) {
            console.log(`Remove point: ${data}`)
            
            var self = this
            this.markers.forEach(function(marker, index) {
                if (marker.uuid === data) {
                    marker.marker.remove()
                    self.markers.splice(index, 1)
                }
            })

            console.log(JSON.stringify(this.markers))
        },
        event_update: function (data) {
            console.log(`Recieved point event: ${JSON.stringify(data)}`)

            let event = data
            let found = false
            var self = this

            self.pointHistory.forEach(function (p) {
                if (p.uuid === event.pointUUID) {
                    p.events.push({uuid: event.uuid, pointUUID: event.pointUUID, callsign: event.callsign, direction: event.direction, time: event.time})
                    found = true
                }
            })

            if (!found) {
                var events = []
                
                events.push({uuid: event.uuid, pointUUID: event.pointUUID, callsign: event.callsign, direction: event.direction, time: event.time})
                self.pointHistory.push({uuid: event.pointUUID, name: event.name, events: events})
            }
        },
        layer_update: function (data) {
            var self = this
            var matchFound = false

            self.layers.forEach(function (layer) {
                if (layer.uuid === data.uuid) {
                    console.log(`Updating layer: ${data.uuid}`)
                    layer.name = data.name
                    matchFound = true
                }
            })

            if (matchFound != true) {
                self.layers.push({uuid: data.uuid, nickname: data.name})

                const layerUUID = data.uuid
                var overlay = new window.maptalks.VectorLayer(`overlay-${layerUUID}`).addTo(self.map)

                data.geojson.features.forEach(function (data) {
                    if (data.geometry.type === 'Point') {
                        let marker1 = new window.maptalks.Marker(
                        [data.geometry.coordinates[0], data.geometry.coordinates[1]],
                        {
                        'symbol' : {
                            'markerType': data.properties.icon.includes('diamond') ? 'diamond' : 'square',
                            'markerFill': data.properties.icon.includes('square') ? 'blue' : 'red',
                            'markerLineColor': '#000',
                            'markerLineWidth': '2',
                            'markerLineDasharray':[],
                            'markerWidth': data.properties.icon.includes('square') ? '20' : '15',
                            'markerHeight': data.properties.icon.includes('square') ? '20' : '15',
                            'markerDx': 0,
                            'markerDy': 0,
                            'markerOpacity' : 0.5,
                            'textName': data.properties.name,
                            'textWeight': '900',
                            'textFont': 'small-caps bold 14px/1 sans-serif',
                            'textFill': '#fff',
                            'textHaloFill': '#000',
                            'textHaloRadius': '2',
                            'textDy': '20',
                            'textDx': '0'
                        }
                        }).on('click', function () {
                        self.showMarkerDetails(`${layerUUID}-${data.properties.name}`)
                        })

                        overlay.addGeometry(marker1)

                        self.markers.push({uuid: `${layerUUID}-${data.properties.name}`, type: 'point', layerUUID: layerUUID, nickname: data.properties.name, lon: data.geometry.coordinates[0], lat: data.geometry.coordinates[1], marker: marker1})
                    }

                    if (data.geometry.type === "LineString") {
                        let routeColor;
                        let uuid = uuidv4

                        for (let c of self.routeColors) {
                            if (!c.used) {
                                routeColor = c.color
                                c.used = true
                                break
                            }
                        }

                        let marker1 = new window.maptalks.LineString(
                        data.geometry.coordinates,
                        {
                            arrowStyle : null, // arrow-style : now we only have classic
                            arrowPlacement : 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
                            visible : true,
                            editable : false,
                            cursor : null,
                            shadowBlur : 0,
                            shadowColor : 'black',
                            draggable : false,
                            dragShadow : false, // display a shadow during dragging
                            drawOnAxis : null,  // force dragging stick on a axis, can be: x, y
                            symbol: {
                                'lineColor' : routeColor,
                                'lineWidth' : 5,
                                'lineDasharray': [10, 20],
                                'textName': data.properties.name,
                                'textWeight': '900',
                                'textFont': 'small-caps bold 14px/1 sans-serif',
                                'textFill': '#fff',
                                'textHaloFill': '#000',
                                'textHaloRadius': '2',
                                'textDy': '20',
                                'textDx': '0'
                            }
                        }).on('click', function () {
                        self.showLineDetails(data.uuid)
                        })

                        overlay.addGeometry(marker1)

                        self.markers.push({uuid: uuid, type: 'line', layerUUID: layerUUID, nickname: data.properties.name, marker: marker1})
                    }
                })

                self.routeColors.forEach(function (c) {
                    c.used = false
                })

                M.toast({html: `Yay!:\xa0\xa0<b style="font-weight: 700;">Layer added</b>`, classes: 'rounded alert'})
            }
        },
        remove_layer: function (data) {
            console.log(`Removing layer: ${data}`)
            
            var self = this
            const uuid = data

            self.markers.forEach(function(marker, index) {
                if ('layerUUID' in marker) {
                    console.log('Found')
                    if (marker.layerUUID === uuid) {
                        console.log(marker)
                        self.markers.splice(index, 1)
                    }
                }
            })

            self.layers.forEach(function (layer, index) {
                if (layer.uuid === uuid) {
                    self.layers.splice(index, 1)
                }
            })

            self.map.removeLayer(`overlay-${uuid}`)
        },
        instruction: function (instruction) {
            this.instruction = instruction
            this.alertAudio.play()
            console.log(instruction)
        },
        track_sync: function (data) {
            var self = this

            const trackUUID = data.uuid
            var overlay = new window.maptalks.VectorLayer(`overlay-${trackUUID}`).addTo(self.map)
            self.map.getLayer(`overlay-${trackUUID}`).bringToBack()

            let points = []

            data.points.forEach(function (point) {
                points.push([point.longitude, point.latitude])
            })

            self.markers.forEach(function (marker) {
                if (marker.uuid === data.uuid) {
                    let uuid1 = data.uuid + '-track'
                    self.tracks.push({uuid: uuid1, points: data.points})
                    //points.push([marker.geolocation.longitude, marker.geolocation.latitude])

                    let marker1 = new window.maptalks.LineString(
                        points,
                        {
                            arrowStyle : null, // arrow-style : now we only have classic
                            arrowPlacement : 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
                            visible : true,
                            editable : false,
                            cursor : null,
                            shadowBlur : 0,
                            shadowColor : 'black',
                            draggable : false,
                            dragShadow : false, // display a shadow during dragging
                            drawOnAxis : null,  // force dragging stick on a axis, can be: x, y
                            symbol: {
                                'lineColor' : 'red',
                                'lineOpacity' : 0.7,
                                'lineWidth' : 3,
                                'textWeight': '900',
                                'textFont': 'small-caps bold 14px/1 sans-serif',
                                'textFill': '#fff',
                                'textHaloFill': '#000',
                                'textHaloRadius': '2',
                                'textDy': '20',
                                'textDx': '0'
                            }
                        }).on('click', function () {
                        self.showLineDetails(data.uuid)
                        })

                    overlay.addGeometry(marker1)

                    let uuid = data.uuid + '-track'
                    self.markers.push({uuid: uuid, type: 'line', marker: marker1})
                }
            })
        },
        track_update: function (data) {
            var self = this

            this.markers.forEach(function (marker, index) {
                let uuid = data.uuid + '-track'

                if ((marker.uuid + '-track') == uuid) {
                    console.log("FOUND")
                    self.tracks.splice(index, 1)
                    self.map.removeLayer('overlay-' + marker.uuid)

                    var overlay = new window.maptalks.VectorLayer(`overlay-${data.uuid}`).addTo(self.map)
                    self.map.getLayer(`overlay-${data.uuid}`).bringToBack()

                    let points = []

                    data.points.forEach(function (point) {
                        points.push([point.longitude, point.latitude])
                    })
                    
                    let marker1 = new window.maptalks.LineString(
                        points,
                        {
                            arrowStyle : null, // arrow-style : now we only have classic
                            arrowPlacement : 'point', // arrow's placement: vertex-first, vertex-last, vertex-firstlast, point
                            visible : true,
                            editable : false,
                            cursor : null,
                            shadowBlur : 0,
                            shadowColor : 'black',
                            draggable : false,
                            dragShadow : false, // display a shadow during dragging
                            drawOnAxis : null,  // force dragging stick on a axis, can be: x, y
                            symbol: {
                                'lineColor' : 'red',
                                'lineOpacity' : 0.7,
                                'lineWidth' : 3,
                                'textWeight': '900',
                                'textFont': 'small-caps bold 14px/1 sans-serif',
                                'textFill': '#fff',
                                'textHaloFill': '#000',
                                'textHaloRadius': '2',
                                'textDy': '20',
                                'textDx': '0'
                            }
                        }).on('click', function () {
                        self.showLineDetails(data.uuid)
                        })

                    overlay.addGeometry(marker1)

                    let uuid = data.uuid + '-track'
                    self.markers.push({uuid: uuid, type: 'line', marker: marker1})
                }
            })
        },
    },
    mounted: function () {
        this.$nextTick(()=>{
            this.myID = localStorage.getItem("uuid")
            this.isWhitecell = localStorage.getItem("isWhitecell")

            this.$socket.emit('send_uuid', {uuid: this.myID, nickname: this.nickname, isWhitecell: this.isWhitecell})

            var self = this
            window.$(document).ready(function(){
                self.initMap()
                
                if (self.isWhitecell == 0) {
                    self.enableGeolocationServices()
                }

                self.alertAudio = new Audio(alertSound)
            })
        })
    }
}
</script>

<style scoped>
    .wrapper {
        z-index: +1;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
        background: rgb(124,77,255);
        background: linear-gradient(20deg, rgba(124,77,255,1) 0%, rgba(81,45,168,1) 50%, rgba(66,66,66,1) 100%);
    }
    .map {
        z-index: +10;
        height: 100vh;
        width: 100%;
    }

    .alert {
        margin: 10px !important;
    }

    .map-controls {
        z-index: +1;
        position: absolute;
        top: 100px;
        right: 25px;
        padding: 15px 10px 15px 10px;
        background: #000;
        opacity: 0.8;
        border-radius: 8px;
    }

    .map-controls i {
        z-index: +2;
        float: left;
        font-size: 16pt;
        cursor: pointer;
        margin: 0px 10px 0px 10px;
    }

    .map-layers {
        z-index: +1;
        position: absolute;
        top: 160px;
        right: 25px;
        padding: 15px 10px 15px 10px;
        background: #000;
        color: #fff;
        opacity: 0.8;
        border-radius: 8px;
        font-size: 14pt;
        font-weight: 700;
    }

    .map-layers i {
        z-index: +2;
        float: left;
        cursor: pointer;
        padding: 2px;
        font-size: 14pt;
        line-height: 24px;
    }
</style>