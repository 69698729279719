require('dotenv').config()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import 'animate.css'

Vue.config.productionTip = false

let server;
if (process.env.NODE_ENV === "development") {
  server = localStorage.getItem('API_PATH')

  if (server === null) {
    server = "https://localhost:3000"
  }
} else {
  server = localStorage.getItem('API_PATH')

  if (server === null) {
    server = "https://waitforupdate/"
  }
}

const socketConnection = SocketIO(server);

Vue.use(new VueSocketIO({
  debug: false,
  connection: socketConnection
}))

console.log(process.env)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
