<template>
    <div v-on:click="close()">
        <div class="instruction-bg"></div>
        <div class="row wrapper valign-wrapper">
            <div class="instruction grey darken-3 white-text blue-grey darken-4 animate__animated animate__heartBeat">
                <div class="type">{{instruction.type.toUpperCase()}}</div>
                <div class="message" v-if="instruction.type === 'message'">
                    <p>{{instruction.message}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InstructionAlert',
    data: function () {
        return {
        }
    },
    props: {
        instruction: Object
    },
    methods: {
        close: function () {
            this.$emit('closeInstruction')
        },
    }
}
</script>

<style scoped>
    .instruction-bg {
        z-index: +2;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        margin: 0px;
        background: #000;
        opacity: 0.75;
    }

    .wrapper {
        z-index: +3;
        position: absolute;
        width: 100%;
        height: 100vh;
    }

    .instruction {
        width: 500px;
        max-width: 85%;
        margin: 0px auto;
        padding: 50px 0px 50px 0px;
        text-align: center;
        border-radius: 20px;
    }

    .type {
        font-size: 22pt;
        font-weight: 700;
    }

    p {
        text-align: left;
        padding: 0px 20px 0px 20px;
        margin-bottom: 0px;
        font-size: 14pt;
        font-weight: 400;
        max-height: 200px;
    }
</style>