<template>
    <div class="marker-details row">
        <div class="pane col s12 m6 l4 offset-m6 offset-l8 grey darken-3 blue-grey darken-4 white-text">
            <div class="row content">
                <div class="col s12">
                    <div class="lead">Details <i class="fas fa-times close right" v-on:click="close()"></i></div>
                    <ul>
                        <li><b>Coords:</b> {{mgrs.forward([details.geolocation.longitude, details.geolocation.latitude])}}<br />{{details.geolocation.latitude.toFixed(6)}} {{details.geolocation.longitude.toFixed(6)}}</li>
                        <li v-if="details.geolocation.altitude != null"><b>Elevation:</b> {{Math.round(details.geolocation.altitude * 3.281)}} ft</li>
                        <li v-if="details.geolocation.speed != null"><b>Movement details</b></li>
                        <li class="movement" v-if="details.geolocation.speed != null"><b>Speed:</b> {{Math.round(details.geolocation.speed * 2.23694)}} MPH</li>
                        <li class="movement" v-if="details.geolocation.heading != null"><b>Heading:</b> {{Math.round(details.geolocation.heading)}}</li>
                    </ul>
                    <div class="row commands">
                        <div class="col s12 lead">Commands</div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn modal-trigger" href="#eventModal">Log event</a>
                        </div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="send_instruction('stop', details.uuid)">Stop</a>
                        </div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="send_instruction('go', details.uuid)">Go</a>
                        </div>
                        <div class="col s12 m6 l4">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="prepare_message(details.uuid)">Message</a>
                        </div>
                    </div>
                    <div class="row commands input" v-if="showMessageInput">
                        <div class="input-field col s12">
                            <textarea id="textarea1" class="materialize-textarea white-text" v-model="message"></textarea>
                            <label for="textarea1">Enter your message</label>
                        </div>
                        <div class="col s12 m4 offset-m8">
                            <a class="yellow darken-1 black-text waves-effect waves-light btn" v-on:click="send_instruction('message', details.uuid)">Send</a>
                        </div>
                    </div>
                </div>
                <div class="col s12 uuid"><span class="left">{{details.nickname}} <i class="fas fa-trash yellow-text text-darken-1" v-on:click="remove_user(details.uuid)"></i></span>{{details.uuid}}</div>
            </div>
        </div>

        <div id="eventModal" class="grey darken-4 blue-grey darken-5 white-text modal">
            <div class="modal-content">
                <div class="row">
                    <h4>{{ details.nickname }}</h4>
                </div>
                <div class="row events">
                    <div class="col s12 m6 l4 event">
                        <i class="fas fa-skull-crossbones"></i>
                        <p>Remove</p>
                    </div>
                    <div class="col s12 m6 l4 event">
                        <i class="fas fa-heartbeat"></i>
                        <p>Regen</p>
                    </div>
                    <div class="col s12 m6 l4 event">
                        <i class="fas fa-fighter-jet"></i>
                        <p>Engage</p>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as mgrs from "mgrs"

export default {
    name: 'MarkerDetails',
    data: function () {
        return {
            mgrs: mgrs,
            showMessageInput: false,
            messageTo: null,
            message: null
        }
    },
    props: {
        details: Object
    },
    methods: {
        close: function () {
            this.$emit('closeMarkerDetails')
        },
        send_instruction: function (type, uuid) {
            this.$socket.emit('send_instruction', {type: type, to: uuid, message: this.message})
            this.close() 
        },
        prepare_message: function (uuid) {
            this.showMessageInput = true
            this.messageTo = uuid
        },
        remove_user: function (uuid) {
            this.$socket.emit('remove_user', uuid)
            this.close()
        }
    },
    mounted: function () {
        window.materialize.AutoInit()
    }
}
</script>

<style scoped>
    .tooltipped {
        z-index: +10;
    }

    .marker-details {
        z-index: +2;
        position: absolute;
        top: 82px;
        right: 0px;
        width: 100%;
        margin: 0px;
    }

    .pane {
        margin: 0px;
        border-top: 1px solid #fdd835;
    }

    .pane .content {
        margin: 15px 0px 15px 0px;
    }

    .content .lead {
        width: 100%;
        margin-bottom: 10px;
        font-size: 20pt;
        font-weight: 600;
    }

    .lead .close {
        cursor: pointer;
    }

    .content ul {
        font-size: 14pt;
        margin: 0px;
    }

    li.movement {
        margin-left: 10px;
        font-size: 12pt;
        font-weight: 400;
    }

    .uuid {
        margin-top: -15px;
        text-align: right;
        font-size: 10pt;
        font-weight: 700;
    }

    .commands .lead {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .commands a {
        width: 100%;
        margin-bottom: 15px;
    }

    .input-field textarea:focus + label {
        color: #fdd835 !important;
    }

    .row .input-field textarea:focus {
        border-bottom: 1px solid #fdd835 !important;
        box-shadow: 0 1px 0 0 #fdd835 !important
    }

    .uuid i {
        padding: 5px;
        cursor: pointer;
    }

    .events {
        text-align: center;
        margin-bottom: 0px;
    }

    .event {
        cursor: pointer;
    }

    .event i {
        font-size: 50pt;
        color: #fdd835
    }

    .event p {
        font-size: 16pt;
        font-weight: 600;
    }

    @media only screen and (max-width: 1200px) {
        .marker-details {
            z-index: +2;
            position: absolute;
            top: 80px;
            width: 100%;
            margin: 0px;
        }
    }
</style>