<template>
    <div>
        <div class="map-controls">
            <div class="row" style="margin-bottom: 0px;">
                <i class="fas fa-user-shield yellow-text text-darken-1" v-on:click="toggleList()"></i>
            </div>
        </div>
        <div class="whitecell-list white-text" v-if="showList">
            <div class="row">
                <div class="whitecell-pane col s12 m6 l5">
                    <p class="lead">Active Whitecell</p>
                    <div class="row" v-for="(whitecell) in whitecell" :key="whitecell">
                        <div class="col s12">
                            <h4 class="yellow-text text-dark-1 left">{{ whitecell.nickname }}</h4>
                            <a class="yellow darken-1 black-text waves-effect waves-light btn left modal-trigger" style="top: 18px; left: 10px;" href="#whitecellModal" v-on:click="setActiveSelection(whitecell.uuid, whitecell.nickname)"><i class="fas fa-comment"></i></a>
                            <h5>UUID: {{ whitecell.uuid }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal grey darken-3 blue-grey darken-4 white-text" id="whitecellModal">
            <div class="modal-content">
                <h4>Message {{ activeSelection.nickname }}</h4>
                <div class="input-field col s12">
                    <textarea id="textarea1" class="materialize-textarea white-text" v-model="message"></textarea>
                    <label for="textarea1">Enter your message</label>
                </div>
            </div>
            <div class="modal-footer yellow darken-1">
                <a class="grey darken-4 blue-grey darken-5 white-text waves-effect waves-light btn modal-close" v-on:click="send_instruction('message', activeSelection.uuid)">Send</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Whitecell',
    data: function () {
        return {
            showList: false,
            activeSelection: {}
        }
    },
    props: {
        whitecell: Array
    },
    methods: {
        send_instruction: function (type, uuid) {
            this.$socket.emit('send_instruction', {type: type, to: uuid, message: this.message})
            this.showList = false
        },
        toggleList: function () {
            if (this.showList) { 
                this.showList = false
            } else {
                this.showList = true
            }
        },
        setActiveSelection: function (uuid, nickname) {
            this.activeSelection = {uuid: uuid, nickname: nickname}
        }
    },
    mounted: function () {
        window.materialize.AutoInit()
    }
}
</script>

<style scoped>
    .input-field textarea:focus + label {
        color: #fdd835 !important;
    }

    .input-field textarea:focus {
        border-bottom: 1px solid #fdd835 !important;
        box-shadow: 0 1px 0 0 #fdd835 !important
    }
    .map-controls {
        z-index: +1;
        position: absolute;
        top: 100px;
        left: 25px;
        width: fit-content;
        padding: 15px 10px 15px 10px;
        background: #000;
        opacity: 0.8;
        border-radius: 8px;
    }

    .map-controls i {
        z-index: +2;
        float: left;
        font-size: 16pt;
        cursor: pointer;
        margin: 0px 10px 0px 10px;
    }

    .map-layers {
        z-index: +1;
        position: absolute;
        top: 160px;
        left: 25px;
        padding: 15px 10px 15px 10px;
        background: #000;
        color: #fff;
        opacity: 0.8;
        border-radius: 8px;
        font-size: 14pt;
        font-weight: 700;
    }

    .map-layers i {
        z-index: +2;
        float: left;
        cursor: pointer;
        padding: 2px;
        font-size: 14pt;
        line-height: 24px;
    }

    .whitecell-list {
        z-index: +1;
        position: absolute;
        top: 150px;
        left: 25px;
        right: 25px;
        padding: 15px 0px 15px 0px;
    }

    .whitecell-list .whitecell-pane {
        background: #000;
        opacity: 0.8;
        border-radius: 8px;
    }

    .lead {
        width: 100%;
        margin-bottom: 10px;
        font-size: 20pt;
        font-weight: 600;
    }

    h4 {
        font-size: 18pt;
        font-weight: 300;
    }

    h5 {
        font-size: 12pt;
        font-weight: 200;
        clear: both;
    }
</style>