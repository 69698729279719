import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Opnet from '../views/Opnet.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      title: "Opnet - Connected OPFOR Events"
    }
  },
  {
    path: '/map',
    name: 'Opnet',
    component: Opnet,
    meta: {
      title: 'Opnet - Live'
    }
  },
  {
    path: "/:catchAll(.*)",
    component: PageNotFound,
    meta: {
      title: "Opnet- Page not found"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  // Placeholder for future authentication provider as needed
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
