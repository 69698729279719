<template>
    <div>
        <div class="row nav">
            <div class="col s12 grey darken-3 white-text blue-grey darken-4">
                <div class="row no-margin-bottom">
                    <div class="col s12">
                        <ul class="menu left">
                            <li class="brand"><router-link to="/" exact>Opnet</router-link></li>
                        </ul>
                        <ul class="menu right hide-on-med-and-down">
                            <li><router-link to="/" exact>Welcome</router-link></li>
                            <li><router-link to="/map" exact>Live</router-link></li>
                        </ul>
                        <ul class="menu right hide-on-med-and-up">
                            <li data-target="mobile-menu2" class="sidenav-trigger"><i class="fas fa-ellipsis-v"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <ul id="mobile-menu2" class="mobile-menu2 sidenav grey darken-3 white-text blue-grey darken-4">
            <li><router-link to="/" extact class="mobile-menu">Welcome</router-link></li>
            <li><router-link to="/map" extact class="mobile-menu">Map</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'OpnetNav',
    props: {
        map: Object
    },
    mounted: function () {
        window.materialize.AutoInit()
    }
}
</script>

<style scoped>
    .mobile-menu2 {
        font-size: 14pt;
        font-weight: 700;
    }

    #mobile-menu2, .sidenav {
        text-align: center;
    }

    .sidenav-overlay {
        z-index: -10;
    }

    a:link, a:visited {
        color:inherit;
        background-color: transparent;
        text-decoration: none;
    }

    .no-margin-bottom {
        margin-bottom: 0px;
    }

    .nav {
        z-index: +1;
        position: absolute;
        top: 0px;
        width: 100%;
        margin: 0px;
    }

    .menu {
        padding: 8px;
    }

    .mobile-menu {
        font-size: 14pt;
        font-weight: 700;
    }

    #mobile-menu {
        text-align: center;
    }

    .router-link-exact-active, .router-link-active {
        color: #fdd835 !important;
    }

    .menu .brand {
        font-size: 18pt;
        font-weight: 700;
    }

    .menu i {
        cursor: pointer;
    }

    .menu li {
        display: inline;
        margin: 0px 7.5px 0px 7.5px;
        font-size: 14pt;
    }
</style>