<template>
    <div class="pagenotfound valign-wrapper">
        <OpnetNav />
        <div class="container">
            <div class="row">
                <div class="box col s12 m6 l4 offset-m3 offset-l4 grey darken-3 white-text" style="padding: 15px;">
                    <div class="col s12 title">Uh oh!</div>
                    <div class="col s12 welcome">
                        We're sorry, that page was not found.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OpnetNav from '@/components/Opnet/OpnetNav.vue'

export default {
    name: 'PageNotFound',
    components: {
        OpnetNav
    }
}
</script>

<style scoped>
    .pagenotfound {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
        background: rgb(124,77,255);
        background: linear-gradient(20deg, rgba(124,77,255,1) 0%, rgba(81,45,168,1) 50%, rgba(66,66,66,1) 100%);
    }
    .box {
        border: 2px solid #616161;
        border-radius: 10px;
    }

    .box .title {
        font-size: 20pt;
        text-align: center;
    }

    .box .welcome {
        font-size: 14pt;
        font-weight: 300;
        margin: 15px 0px 15px 0px;
        text-align: center;
    }

    .box .start {
        width: 100%;
        margin: 10px 0px 10px 0px;
    }
</style>