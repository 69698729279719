<template>
    <div class="wrapper">
        <div class="overlay"></div>
        <div class="row main-row">
            <div class="col s12 m6 l4 offset-m6 offset-l8 grey darken-3 white-text blue-grey darken-4">
                <div class="row no-margin-bottom">
                    <div class="col s12">
                        <ul class="menu left">
                            <li class="brand"><a href="/">Opnet</a></li>
                        </ul>
                        <ul class="menu right hide-on-med-and-down">
                            <li><router-link to="/" exact>Welcome</router-link></li>
                            <li><router-link to="/map" exact>Live</router-link></li>
                        </ul>
                        <ul class="menu right hide-on-med-and-up">
                            <li data-target="mobile-menu" class="sidenav-trigger"><i class="fas fa-ellipsis-v"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row content-row">
            <div class="box col s12 m6 l4 offset-m6 offset-l8 grey darken-3 white-text valign-wrapper" style="padding: 15px;">
                <div>
                    <div class="center-align">
                        <img class="responsive-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/1st_Special_Operations_Wing.svg/1200px-1st_Special_Operations_Wing.svg.png" />
                    </div>
                    <div class="col s12 title">Opnet</div>
                    <div class="col s12 welcome">
                        Conduct connected OPFOR events with geospaital awareness, tap-enabled command and control, and test range data. Brought to you by 14 WPS/WSI.
                    </div>
                    <div class='col s12 m6 offset-m3'>
                        <a href="#identityModal" class="modal-trigger start btn waves-effect waves-dark yellow darken-1 black-text">Start</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row footer">
            <div class="col s12 m6 l4 offset-m6 offset-l8 grey darken-3 white-text blue-grey darken-4">
                <ul class="menu center-align">
                    <li>About</li>
                    <li>Features</li>
                    <li>Updates</li>
                </ul>
                <ul class="menu imprint center-align">
                    <li>Created with &#x2764; by <a href="mailto:matthew.reichardt.2@us.af.mil">M. Reichardt</a></li>
                </ul>
            </div>
        </div>
        <ul id="mobile-menu" class="sidenav sidenav-close grey darken-3 white-text blue-grey darken-4">
            <li><router-link to="/" extact class="mobile-menu">Welcome</router-link></li>
            <li><router-link to="/map" extact class="mobile-menu">Map</router-link></li>
        </ul>
        <div id="identityModal" class="grey darken-4 blue-grey darken-5 white-text modal">
            <div class="modal-content">
                <h4>Hold up....</h4>
                <p>Enter a nickname for yourself</p>
                <div class="input-field col s12">
                    <input id="last_name" type="text" class="validate white-text" v-model="nickname">
                    <label for="last_name">Nickname</label>
                </div>
                <div>
                    <h5>Enter API endpoint</h5>
                    <p>This should be provided to you</p>
                    <div class="input-field col s12">
                        <input id="apiPathInput" type="text" class="validate white-text" v-model="API_PATH">
                        <label for="ApiPath">API Endpoint</label>
                    </div>
                </div>
                <div style="margin-bottom: 50px;">
                    <div class="input-field col s12">
                        <label class="white-text">
                            <input id="isWhitecell" type="checkbox" v-model="isWhitecell" />
                            <span>Whitecell?</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer yellow darken-1">
                <a class="start btn waves-effect waves-light grey darken-4 blue-grey darken-5 white-text" v-on:click="setApiPath()">Set details</a>
            </div>
        </div>
    </div>
</template>

<script>

import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'WelcomeBox',
    data: function () {
        return {
            nickname: null,
            API_PATH: null,
            isWhitecell: 0
        }
    },
    methods: {
        setApiPath: function () {
            this.API_PATH = document.getElementById("apiPathInput").value

            if (this.nickname != localStorage.getItem("nickname") || localStorage.getItem("uuid") == "") {
                localStorage.setItem('uuid', uuidv4())
            }
  
            localStorage.setItem("nickname", this.nickname)
            localStorage.setItem("API_PATH", this.API_PATH)
            localStorage.setItem("isWhitecell", this.isWhitecell)

            this.$router.push({ path: 'map' })
            this.$router.go(0)
        }
    },
    mounted: function () {
        window.materialize.AutoInit()
        this.nickname = localStorage.getItem("nickname")
        this.API_PATH = localStorage.getItem("API_PATH")
    }
}
</script>

<style scoped>
    .no-margin-bottom {
        margin-bottom: 0px;
    }

    a:link, a:visited {
        color:inherit;
        background-color: transparent;
        text-decoration: none;
    }

    .wrapper {
        height: 100vh;
    }

    .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
        background: #000;
        opacity: 0.20;
    }

    .main-row {
        z-index: +1;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        margin: 0px;
    }

    .menu {
        padding: 8px;
    }

    .mobile-menu {
        font-size: 14pt;
        font-weight: 700;
    }

    #mobile-menu {
        text-align: center;
    }

    .footer .menu {
        padding: 0px 0px 0px 0px;
    }

    .footer .imprint {
        margin-top: 0px;
    }

    .menu li {
        display: inline;
        margin: 0px 7.5px 0px 7.5px;
        font-size: 14pt;
    }

    .footer .menu li {
        display: inline;
        margin: 0px 7.5px 0px 7.5px;
        font-size: 12pt;
        font-weight: 400;
    }

    .footer .imprint li {
        font-weight: 300;
        font-size: 11pt;
    }

    .footer .imprint a {
        color: #fdd835;
        text-decoration: underline;
        text-underline-position: under
    }

    .router-link-exact-active, .router-link-active {
        color: #fdd835 !important;
    }

    .menu .brand {
        font-size: 18pt;
        font-weight: 700;
    }

    .menu i {
        cursor: pointer;
    }

    .content-row {
        margin-bottom: 0px;
        height: 100%;
    }

    .box {
        position: relative;
        height: 100%;
    }

    .box img {
        max-width: 75%;
        width: 275px;
        margin: 0px auto;
        margin-bottom: 15px;
    }

    .box .title {
        font-size: 24pt;
        text-align: center;
        font-weight: 700;
    }

    .box .welcome {
        font-size: 16pt;
        font-weight: 300;
        margin: 15px 0px 15px 0px;
    }

    .box .start {
        width: 100%;
        margin: 30px 0px 10px 0px;
    }

    .footer {
        z-index: +1;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        margin: 0px;
    }

    #identityModal {
        width: 500px;
        max-width: 80%;
    }

    .input-field {
        margin-bottom: 0px;
    }

    .input-field input:focus + label {
        color: #fdd835 !important;
    }

    .input-field input:focus {
        border-bottom: 1px solid #fdd835 !important;
        box-shadow: 0 1px 0 0 #fdd835 !important
    }

    @media only screen and (max-width: 600px) {
        .box img {
            width: 125px;
            margin: 0px auto;
        }
    }
</style>