<template>
    <div class="bottom-bar grey darken-3 blue-grey darken-4 white-text">
        <div class="left version">
            <ul>
                <li class="lead"><b>Opnet</b> v{{version}}</li>
                <li>About</li>
                <li>Features</li>
                <li>Updates</li>
            </ul>
        </div>
        <div class="right hide-on-small-only">
            <div class="item" style="width: 315px;">
                <div class="left"><b>Coordinates:</b></div>
                <span class="left">{{mousePosition}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OpnetBottomBar',
    data: function () {
        return {
            mousePosition: "Move mouse"
        }
    },
    props: {
        version: String,
        map: Object
    },
    mounted: function () {
        var self = this
        this.map.on('mousemove', function (param) {
            self.mousePosition = `Lat: ${param.coordinate.toFixed(5).y} Lon: ${param.coordinate.toFixed(5).x}`
        });
    }
}
</script>

<style scoped>
    .bottom-bar {
        z-index: +100;
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 5px 10px 5px 10px;
        width: 100%;
    }

    .item span {
        padding-left: 10px;
    }

    ul {
        margin: 0px;
    }

    ul .lead {
        font-weight: 500;
    }

    li {
        display: inline;
        margin-right: 15px;
        font-weight: 300;
    }
</style>
