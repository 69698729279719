<template>
  <div id="app" class="grey darken-3">
    <router-view />
  </div>
</template>
<script>

window.$ = window.jQuery = require('jquery');
window.materialize = require('materialize-css');

export default {
  sockets: {
    connect: function () {
        console.log('websocket connected')
        localStorage.setItem("socketConnected", "true")
        window.M.toast({html: `Broadcasts:\xa0\xa0<b style="font-weight: 700;">Connected</b>`, classes: 'rounded alert'})
    },
    disconnect: function () {
        console.log('websocket disconnected')
        localStorage.setItem("socketConnected", "false")
        window.M.toast({html: `Broadcasts:\xa0\xa0<b style="font-weight: 700;">Unavailable</b>`, classes: 'rounded alert'})
    }
  }
}
</script>